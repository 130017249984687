<template>
  <v-container class="custom-line-item-template pb-0" fluid>
    <v-row class="custom-solo-input">
      <v-col cols="6" class="pt-0 pb-0">
        <template v-if="false">
          <v-textarea
            v-model="lineItemCalculation.description"
            auto-grow
            dense
            filled
            flat
            :disabled="pageLoading"
            label="Description"
            solo
            class="remove-border-radius"
            hide-details
            row-height="20"
          ></v-textarea>
        </template>
      </v-col>
      <v-col
        cols="6"
        class="pt-0 pb-0"
        :class="{ 'disable-click': calculationLoader }"
      >
        <div class="d-none">
          <v-text-field
            type="hidden"
            v-model="calculationLoader"
            :rules="[validateRules.required(!calculationLoader)]"
          ></v-text-field>
        </div>
        <div v-if="calculationLoader" class="custom-calculation-loader">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="cyan"
            class="calculation-loader"
          ></v-progress-circular>
        </div>
        <table class="width-100 vertical-align-middle">
          <tr>
            <td
              valign="center"
              class="font-size-16 py-3 font-weight-500"
              colspan="2"
            >
              Sub Total
            </td>
            <td
              valign="center"
              class="font-size-16 py-3 text-right"
              width="150"
            >
              {{
                formatMoney(accountingToFixed(lineItemCalculation.subTotal, 2))
              }}
            </td>
          </tr>
          <tr>
            <td
              valign="center"
              class="font-size-16 pb-2 font-weight-500"
              width="150"
            >
              Discount
            </td>
            <td
              valign="center"
              class="font-size-16 px-lg-8 px-md-4 px-sm-2 px-xs-2"
            >
              <v-text-field
                dense
                filled
                flat
                type="number"
                min="0"
                :disabled="pageLoading"
                hide-details
                solo
                v-model.number="lineItemCalculation.discountValue"
                class="discount-append remove-border-radius width-100 remove-append-outer-margin"
                v-on:keyup="updateDiscount"
                v-on:change="updateDiscount"
                v-on:mouseup="updateDiscount"
              >
                <template v-slot:append-outer>
                  <v-select
                    :items="discountType"
                    v-model="lineItemCalculation.discountValueType"
                    dense
                    filled
                    flat
                    :disabled="pageLoading"
                    hide-details
                    solo
                    item-text="type"
                    item-value="value"
                    color="cyan"
                    item-color="cyan"
                    class="discount-append-outer remove-border-radius m-0 width-80px hide-border-left"
                    v-on:change="updateDiscount"
                  ></v-select>
                </template>
              </v-text-field>
            </td>
            <td
              valign="center"
              class="font-size-16 pb-2 text-right"
              width="150"
            >
              -{{
                formatMoney(
                  accountingToFixed(lineItemCalculation.discountAmount, 2)
                )
              }}
            </td>
          </tr>
          <tr v-if="false">
            <td valign="center" class="font-size-16 pb-2 font-weight-500">
              Adjustment
            </td>
            <td valign="center" class="font-size-16 px-8">
              <v-text-field
                dense
                filled
                flat
                type="number"
                min="0"
                :disabled="pageLoading || calculationLoader"
                hide-details
                solo
                v-model.number="lineItemCalculation.adjustmentAmount"
                class="remove-border-radius width-100"
                v-on:keypress="
                  limitDecimal($event, lineItemCalculation.adjustmentAmount)
                "
              >
              </v-text-field>
            </td>
            <td
              valign="center"
              class="font-size-16 pb-2 text-right"
              width="150"
            >
              {{
                formatMoney(
                  accountingToFixed(lineItemCalculation.adjustmentAmount, 2)
                )
              }}
            </td>
          </tr>
          <tr v-if="false">
            <td
              valign="center"
              class="font-size-16 py-3 font-weight-500"
              colspan="2"
            >
              Total Discount
            </td>
            <td
              valign="center"
              class="font-size-16 py-3 text-right"
              width="150"
            >
              -{{
                formatMoney(
                  accountingToFixed(lineItemCalculation.discountAmount, 2)
                )
              }}
            </td>
          </tr>
          <tr v-if="!levelOneHide && !isTicket">
            <td
              valign="center"
              class="font-size-16 py-3 font-weight-500"
              colspan="3"
            >
              <div class="tax-field d-flex align-items-center">
                <span class="mr-4">Tax [ {{formatNumber(defaultTaxValue)}}% ]</span>
                <span>
                  <v-checkbox
                    v-on:change="updateTax($event)"
                    color="cyan"
                    hide-details
                    v-model="apply_tax"
                    class="my-0 py-0 mx-0 px-0"
                  >
                  </v-checkbox>
                </span>
              </div>
            </td>
          </tr>
          <tr v-if="!levelOneHide">
            <td
              valign="center"
              class="font-size-16 py-3 font-weight-500"
              colspan="2"
            >
              Taxable Amount
            </td>
            <td
              valign="center"
              class="font-size-16 py-3 text-right"
              width="150"
            >
              {{
                formatMoney(
                  accountingToFixed(
                    lineItemCalculation.subTotal -
                      lineItemCalculation.discountAmount,
                    2
                  )
                )
              }}
            </td>
          </tr>
          <tr v-if="!levelOneHide">
            <td
              valign="center"
              class="font-size-16 py-3 font-weight-500"
              colspan="2"
            >
              Total Tax
            </td>
            <td
              valign="center"
              class="font-size-16 py-3 text-right"
              width="150"
            >
              {{
                formatMoney(accountingToFixed(lineItemCalculation.totalTax, 2))
              }}
            </td>
          </tr>
          <tr>
            <td
              valign="center"
              class="font-size-16 py-3 font-weight-500"
              colspan="2"
            >
              Grand Total
            </td>
            <td
              valign="center"
              class="font-size-16 py-3 text-right"
              width="150"
            >
              {{ formatMoney(accountingToFixed(lineItemCalculation.total, 2)) }}
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import LocalService from "@/core/services/local.service";

export default {
  name: "line-item-calculation",
  mixins: [ValidationMixin, CommonFunctionMixin],
  data() {
    return {
      lineItem: new Array(),
      timeoutLimit: 200,
      timeout: null,
      calculationLoader: false,
      apply_tax: false,
      defaultTaxValue: 0,
      lineItemCalculation: {
        description: null,
        subTotal: 0.0,
        totalTax: 0.0,
        discountAmount: 0.0,
        taxPercentage: 0.0,
        discountValue: 0.0,
        discountType: 1,
        discountValueType: 1,
        adjustmentAmount: 0.0,
        total: 0.0,
      },
      discountType: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
    };
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    updateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    watchLineItem: {
      deep: true,
      immediate: true,
      handler(newObject, oldObject) {
        const _this = this;
        if (!_this.lodash.isEqual(newObject, oldObject)) {
          _this
            .lineItemHaveProduct(newObject)
            .then(() => {
              _this.updateCalculations().catch((error) => {
                _this.logError(error);
              });
            })
            .catch((error) => {
              _this.logError(error);
            });
        }
      },
    },
    /*watchLineItemCalculation: {
      deep: true,
      immediate: true,
      handler(newObject, oldObject) {
        const _this = this;
        if (!_this.lodash.isEqual(newObject, oldObject)) {
          _this
            .updateCalculations()
            .then(() => {
              LineItemCalculationEventBus.$emit(
                "update:line-item-calculation",
                _this.lineItemCalculation
              );
            })
            .catch(error => {
              _this.logError(error);
            });
        }
      }
    },*/
    watchUpdateData: {
      deep: true,
      immediate: true,
      handler(newObject, oldObject) {
        const _this = this;
        if (!_this.lodash.isEqual(newObject, oldObject)) {
          _this.setUpdateData();
        }
      },
    },
  },
  methods: {
    updateTax(param) {
      const _this = this;
      if (param) {
        _this.lineItemCalculation.taxPercentage = _this.defaultTaxValue;
      } else {
        _this.lineItemCalculation.taxPercentage = 0;
      }
      _this.$nextTick(() => {
        _this.updateCalculations().catch((error) => {
          _this.logError(error);
        });
      });
    },
    updateDiscount() {
      const _this = this;
      _this.updateCalculations().catch((error) => {
        _this.logError(error);
      });
    },
    lineItemHaveProduct(lineItem) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            _this.lodash.isEmpty(
              _this.lodash.compact(_this.lodash.map(lineItem, "product"))
            )
          ) {
            reject("Invalid Product");
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    setUpdateData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.updateData) === false) {
        const {
          additional_remarks,
          sub_total,
          tax_amount,
          discount_value,
          discount_type,
          discount_value_type,
          adjustment,
          total,
          tax_value,
        } = _this.updateData;
        const discount_amount = 0.0;
        let tax_pecentage = 0;
        /* if (
          (tax_amount ?? 0.0) > 0 &&
          (sub_total ?? 0.0) > 0 &&
          _this.watchLineItem.length > 0
        ) {
          tax_pecentage =
            (sub_total ?? 0.0) /
            (tax_amount ?? 0.0) /
            _this.watchLineItem.length;
        } */

        if (tax_value) {
          tax_pecentage = tax_value;
          this.apply_tax = true;
        }
        _this.lineItemCalculation = {
          description: additional_remarks ?? null,
          subTotal: sub_total ?? 0.0,
          totalTax: tax_amount ?? 0.0,
          discountAmount: discount_amount ?? 0.0,
          taxPercentage: tax_pecentage ?? 0.0,
          discountValue: discount_value ?? 0.0,
          discountType: discount_type ?? 1,
          discountValueType: discount_value_type ?? 1,
          adjustmentAmount: adjustment ?? 0.0,
          total: total ?? 0.0,
        };
      }
    },
    updateCalculations() {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (_this.lodash.isEmpty(_this.lineItem) === false) {
          _this.$store.dispatch(CLEAR_ERROR, {});
          const lineItems = _this.lineItem.map((row) => {
            return {
              group_active: row.group_active,
              group_primary: row.group_primary,
              rate: row.rate,
              quantity: row.quantity,
              tax: row.tax,
            };
          });
          clearTimeout(_this.timeout);
          _this.timeout = setTimeout(function () {
            _this.calculationLoader = true;
            _this.$store
              .dispatch(POST, {
                url: "line-item/calculate",
                data: {
                  tax_value: _this.lineItemCalculation.taxPercentage,
                  discount: _this.lineItemCalculation.discountValue,
                  discount_type: _this.lineItemCalculation.discountType,
                  discount_value_type:
                    _this.lineItemCalculation.discountValueType,
                  line_items: lineItems,
                  adjustment: _this.lineItemCalculation.adjustmentAmount,
                },
              })
              .then(({ data }) => {
                _this.lineItemCalculation.subTotal =
                  _this.lodash.toNumber(data.sub_total) || 0.0;
                _this.lineItemCalculation.discountAmount =
                  _this.lodash.toNumber(data.discount_amount) || 0.0;
                _this.lineItemCalculation.taxPercentage =
                  _this.lodash.toNumber(data.tax_pecentage) || 0.0;
                _this.lineItemCalculation.adjustmentAmount =
                  _this.lodash.toNumber(data.adjustment) || 0.0;
                _this.lineItemCalculation.totalTax =
                  _this.lodash.toNumber(data.total_tax) || 0.0;
                _this.lineItemCalculation.total =
                  _this.lodash.toNumber(data.total) || 0.0;
                resolve(true);
              })
              .then(() => {
                LineItemCalculationEventBus.$emit(
                  "update:line-item-calculation",
                  _this.lineItemCalculation
                );
              })
              .catch((error) => {
                _this.logError(error);
                reject(error);
              })
              .finally(() => {
                _this.calculationLoader = false;
              });
          }, _this.timeoutLimit);
        } else {
          resolve(true);
        }
      });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");*/
  },
  created() {
    const _this = this;
    _this.setUpdateData();
    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = _this.lodash.cloneDeep(argument);
    });

    const appData = LocalService.getData("app-config");
    if (appData?.gst) {
      _this.defaultTaxValue = appData?.gst;
      _this.lineItemCalculation.taxPercentage = appData?.gst;
    }
  },
  computed: {
    levelOneHide() {
      return this.isContract;
    },
    /*watchLineItemCalculation: function() {
      return this.lodash.cloneDeep(this.lineItemCalculation);
    },*/
    watchUpdateData: function () {
      return this.lodash.cloneDeep(this.updateData);
    },
    watchLineItem: function () {
      return this.lodash.cloneDeep(this.lineItem);
    },
  },
};
</script>
